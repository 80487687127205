// Import our CSS
import "@/css/trimtex.css";
import alpinejs from './global/alpinejs';

// These items should be loaded immediately to avoid layout shifting (CLS)
import 'lazysizes'
import Header from './global/Header'
import MobileMenu from './global/MobileMenu';
import Lightbox from './global/Lightbox';


const init = async function () {

    Header()
    MobileMenu()
    Lightbox()

    const Alpine = alpinejs.setup();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Alpine.asyncData('PDP_Slider', () => import('./components/ProductDetailSlider'))

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Alpine.asyncData('CB_ImageSlider', () => import('./components/ContentBuilderSlider'))

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Alpine.asyncData('TestimonialSlider', () => import('./components/TestimonialSlider'))

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Alpine.asyncData('FeaturedProductsSlider', () => import('./components/FeaturedProductsSlider'))

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Alpine.asyncData('VideoPlayer', () => import('./components/VideoPlayer'))
    Alpine.start();
}

init().then(() => {
    console.log('Done!')
})
